// 繁体

export const demo = {
    demo: '測試'
}

// 常量
export const common = {
    name: '程式軟體發展'
}

// 组件
// lang
export const lang = {
    langObjs: [
        {sx:'en',name:'英語',src:require('@/assets/imgs/gj/en.png')},
        {sx:'tw',name:'繁體',src:require('@/assets/imgs/gj/tw.png')},
        {sx:'cn',name:'中文',src:require('@/assets/imgs/gj/cn.png')},
        {sx:'jp',name:'日語',src:require('@/assets/imgs/gj/jp.png')},
        {sx:'kr',name:'韓語',src:require('@/assets/imgs/gj/kr.png')},
        {sx:'tr',name:'土耳其語',src:require('@/assets/imgs/gj/tr.png')},
    ]
}
// foot
export const foot = {
    foot1: "版權所有",
    foot2: "皖ICP備2020021352號-2",
}

// 页面
// index
export const index = {
    head1_1: "專業仿站網站二次修改開發網站範本定制",
    head1_2: "QQ：597701573",
    head2_1: "各種系統後臺開源系統",
    head3_1: "支持擔保交易",
    head3_2: "放心安心不滿意全額退款",
    head3_3: "微信：V946357",
    head4_1: "速度快價格低售後好",
    head4_2: "快的話2小時完成",
    main1_1: "業務範圍",
    main1_1_1: "QQ：597701573",
    main1_2: "各種網站建設，範本複製，網站製作，網站開發各種網站科技解决，功能開發！",
    main1_3: "標準化合作流程，能够有效保證我們的合作，能够按時、按質、按量的有序進行！",
    main2_1: "程式二次開發",
    main2_2: "程式範本安裝各種問題解决範本二次開發範本調試範本定制！",
    main2_3: "同一時刻只為一比特客戶服務，以保證開發質量，嚴格按照事先約定設計！",
    main3_1: "6年經驗",
    main3_2: "6年經驗，始終如一，真正做到服務好每一位客戶！",
    main3_3: "支持各種交易方式：定金轉帳、擔保交易等！ 更安全，更放心！",
}
