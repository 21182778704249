// 土耳其语

export const demo = {
    demo: 'test'
}

// 常量
export const common = {
    name: 'Program programı geliştirmesi'
}

// 组件
// lang
export const lang = {
    langObjs: [
        {sx:'en',name:'İngilizce',src:require('@/assets/imgs/gj/en.png')},
        {sx:'tw',name:'Traditional Chinese',src:require('@/assets/imgs/gj/tw.png')},
        {sx:'cn',name:'Çince',src:require('@/assets/imgs/gj/cn.png')},
        {sx:'jp',name:'Japonca',src:require('@/assets/imgs/gj/jp.png')},
        {sx:'kr',name:'Korece',src:require('@/assets/imgs/gj/kr.png')},
        {sx:'tr',name:'Türkçe',src:require('@/assets/imgs/gj/tr.png')},
    ]
}
// foot
export const foot = {
    foot1: "Tüm hakları rezerve edildi",
    foot2: "Anhui ICP Hazırlık No. 2020021352-2",
}

// 页面
// index
export const index = {
    head1_1: "Profesyonel imitasyon web sitesi ikinci değişiklik geliştirme web web şablonu özelliği",
    head1_2: "QQ：597701573",
    head2_1: "Çeşitli motor açık kaynak sistemleri",
    head3_1: "Güvenli işlemleri destekliyor",
    head3_2: "Güvenli ve rahatsız olun, tam ödül.",
    head3_3: "WeChat: V946357",
    head4_1: "Hızlı hızlı, düşük fiyat, iyi satış hizmeti",
    head4_2: "Eğer hızlı olursa, 2 saat içinde tamamlanacak.",
    main1_1: "İş alanı",
    main1_1_1: "QQ：597701573",
    main1_2: "Çeşitli web inşaatı, örnek kopyalama, web sitesi üretimi, web geliştirme, çeşitli web sitesi teknoloji çözümleri ve fonksiyonel geliştirme!",
    main1_3: "Standardlı işbirliği süreçleri zamanla, kalite ve miktarla düzenli bir şekilde devam edebilecek işbirliğimizi etkili olarak sağlayabilir.",
    main2_1: "İkinci programı geliştirmesi",
    main2_2: "Programa örnek kurulu Şablon ikinci geliştirme Şablon arızasızlandırma Şablon Özellikleri ile çeşitli sorunlara çözüm!",
    main2_3: "Geliştirme kalitesini sağlamak için bir müşteriye sadece bir müşteriye hizmet edin ve tam olarak anlaştığımız tasarımlara uyun!",
    main3_1: "6 yıllık deneyim",
    main3_2: "6 yıllık deneyim, her müşteriye gerçekten iyi hizmet ediyor!",
    main3_3: "Çeşitli transaksyon metodlarını destekle: depozit transfer, garantiye transaksyon, etc! Güvenli ve daha güvenli!",
}
