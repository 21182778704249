<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from '@/pages/index/Index'
import "@/assets/css/global.css"
import "@/assets/iconfont/iconfont.css"
import {lang} from "@/assets/config/config";

export default {
  name: 'App',
  components: {Index},
  data() {
    return {
      lang: lang.lang,
      langLst: lang.langLst,
    }
  },
  mounted() {
    let lang = localStorage.getItem('lang');
    if (!(lang && this.langLst.includes(lang))) {
      localStorage.setItem('lang', this.lang)
    }
    document.title = this.$t('common.name');
  }
}
</script>

<style lang="scss">
  .single_hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .second_hidden {
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .text_primary {
    color: #409EFF;
  }
  .bg_primary {
    background: #409EFF;
  }
  .text_success {
    color: #67C23A;
  }
  .bg_success {
    background: #67C23A;
  }
  .text_warning {
    color: #E6A23C;
  }
  .bg_warning {
    background: #E6A23C;
  }
  .text_danger,.bg_danger {
    color: #F56C6C;
  }
  .bg_info {
    background: #909399;
  }
  .text_info {
    color: #909399;
  }
  .bg_info {
    background: #909399;
  }
</style>
