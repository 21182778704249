<template>
  <!--底部导航栏-->
  <div class="foot">
    <div>Copyright © 2020-{{ now }} {{ $t('common.title') }} {{$t('foot.foot1')}}</div>
    <div><a href="https://beian.miit.gov.cn/#/Integrated/index">{{$t('foot.foot2')}}</a></div>
  </div>
</template>

<script>
export default {
  name: "Foot",
  data() {
    return {
      now: 2023,
    }
  },
  mounted() {
    this.now = new Date().getFullYear();
  }
}
</script>

<style lang="scss" scoped>
.foot {
  width: 100%;
  /*position: fixed;
  bottom: 0;
  margin-top: 86px;*/
  padding: 20px 0;
  text-align: center;
  color: #fff;
  background: #000;

  div {
    &:last-child {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
</style>
