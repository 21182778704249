import Vue from 'vue'
import App from './App.vue'
import store from './store'
import "@/utils/init"

// import VueRouter from "vue-router";
// import router from "@/router";
// Vue.use(VueRouter);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI);

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || "en",
  // this.$i18n.locale
  messages: {
    'en': require('@/assets/lang/en'),
    'cn': require('@/assets/lang/cn'),
    'tw': require('@/assets/lang/tw'),
    'jp': require('@/assets/lang/jp'),
    'kr': require('@/assets/lang/kr'),
    'tr': require('@/assets/lang/tr'),
  }
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  // router,
  i18n,
  store
}).$mount('#app')
