<template>
  <!-- 下拉菜单 -->
  <div class="lang">
    <el-row>
      <el-col :span="12">
        <el-dropdown trigger="click" @command="setLang">
          <span class="el-dropdown-link">
            <img :src="currentLang.src" alt="Loading"> {{currentLang.name}} <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in langObjs" :key="index"
              :command="item.sx">
              <img :src="item.src" alt="Loading"> {{item.name}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {lang} from "@/assets/config/config";

export default {
  name: "Lang",
  data() {
    return {
      lang: lang.lang,
      langLst: lang.langLst,
      currentLang: {},
      langObjs: this.$t('lang.langObjs'),
    }
  },
  methods: {
    setLang(lang) {
      if (lang && this.langLst.includes(lang)) {
        localStorage.setItem('lang', lang)
        window.location.reload();
      }
    },
  },
  mounted() {
    let lang = localStorage.getItem('lang');
    if (!(lang && this.langLst.includes(lang))) {
      localStorage.setItem('lang', this.lang)
      lang = this.lang;
    }
    this.currentLang = this.langObjs.find(item=>{
      if(item.sx == lang) {
        return item;
      }
    })
    console.log(this.currentLang)
  }
}
</script>

<style lang="scss" scoped>
.lang {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 8px;
  z-index: 999;
  background: #fff;
  img {
    width: 16px;
    height: 12px;
    margin-right: 4px;
  }
}
</style>
