// 韩语

export const demo = {
    demo: '테스트'
}

// 常量
export const common = {
    name: '프로그램 소프트웨어 개발'
}

// 组件
// lang
export const lang = {
    langObjs: [
        {sx:'en',name:'영어',src:require('@/assets/imgs/gj/en.png')},
        {sx:'tw',name:'번체',src:require('@/assets/imgs/gj/tw.png')},
        {sx:'cn',name:'중국어',src:require('@/assets/imgs/gj/cn.png')},
        {sx:'jp',name:'일본어',src:require('@/assets/imgs/gj/jp.png')},
        {sx:'kr',name:'한국어',src:require('@/assets/imgs/gj/kr.png')},
        {sx:'tr',name:'터키어',src:require('@/assets/imgs/gj/tr.png')},
    ]
}
// foot
export const foot = {
    foot1: "저작권 소유",
    foot2: "안휘성 ICP비 2020021352호-2",
}


// 页面
// index
export const index = {
    head1_1: "전문 모방 사이트 2차 수정 개발 사이트 템플릿 사용자 정의",
    head1_2: "QQ：597701573",
    head2_1: "다양한 시스템 백그라운드 오픈 소스 시스템",
    head3_1: "보증 거래 지원",
    head3_2: "안심 안심 불만족 전액 환불",
    head3_3: "위챗: V946357",
    head4_1: "빠른 속도, 저렴한 가격, 판매 후 좋음",
    head4_2: "빠르면 2시간 완성.",
    main1_1: "업무 범위",
    main1_1_1: "QQ：597701573",
    main1_2: "각종 사이트 구축, 템플릿 복제, 사이트 제작, 사이트 개발 각종 사이트 기술 해결, 기능 개발!",
    main1_3: "표준화 합작 절차는 우리의 합작을 효과적으로 보장할 수 있고, 제때, 품질, 양에 따라 질서 있게 진행할 수 있다!",
    main2_1: "프로그램 2차 개발",
    main2_2: "프로그램 템플릿 설치 각종 문제 해결 템플릿 2차 개발 템플릿 디버그 템플릿 사용자 정의!",
    main2_3: "같은 시각에 한 고객에게만 서비스를 제공하여 개발의 질을 보장하고 엄격히 사전 약정에 따라 설계합니다!",
    main3_1: "6년 경험",
    main3_2: "6년의 경험, 시종일관, 진정으로 모든 고객을 잘 서비스합니다!",
    main3_3: "각종 거래 방식을 지원합니다: 계약금 이체, 담보 거래 등!더 안전하고 안심할 수 있습니다!",
}
