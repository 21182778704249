<template>
  <div class="index">
    <!-- 下拉菜单 -->
    <div class="drop">
      <Lang />
    </div>
    <!-- 头部 -->
    <div class="head">
      <!--      <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=597701573&site=qq&menu=yes">-->
      <!--<img src="https://qny.gqqf.xyz/www/advert.jpg"/>-->
      <!-- PC端 -->
      <div class="head_banner bg_primary hidden-md-and-down">
        <div class="head_banner_item1">
          {{$t('index.head1_1')}} <span class="socialize">{{$t('index.head1_1')}}</span>
        </div>
        <div class="head_banner_item2">
          {{$t('index.head2_1')}}
        </div>
        <div class="head_banner_item3">
          <span class="text_danger">{{$t('index.head3_1')}}</span> {{$t('index.head3_2')}} <span class="socialize">{{$t('index.head3_3')}}</span>
        </div>
        <div class="head_banner_item4">
          {{$t('index.head4_1')}}
          <el-tag type="danger" effect="dark">{{$t('index.head4_2')}}</el-tag>
        </div>
      </div>
      <!-- 手机端 -->
      <div class="head_banner2 bg_primary hidden-lg-and-up">
        <div class="head_banner2_item1">
          {{$t('index.head1_1')}} <span class="socialize">{{$t('index.head1_1')}}</span>
        </div>
        <div class="head_banner2_item2">
          {{$t('index.head2_1')}}
        </div>
        <div class="head_banner2_item3">
          <span class="text_danger">{{$t('index.head3_1')}}</span> {{$t('index.head3_2')}} <span class="socialize">{{$t('index.head3_3')}}</span>
        </div>
        <div class="head_banner2_item4">
          {{$t('index.head4_1')}}
          <el-tag type="danger" effect="dark">{{$t('index.head4_2')}}</el-tag>
        </div>
      </div>
      <!--      </a>-->
    </div>
    <!-- 主体 -->
    <div class="main">
      <el-row :gutter="12" class="item">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <i class="iconfont icon-diannao-shuju"></i>
          <h3>{{$t('index.main1_1')}}
            <a href="https://wpa.qq.com/msgrd?v=3&uin=597701573&site=qq&menu=yes" target="_blank">{{$t('index.main1_1_1')}}</a>
          </h3>
          <p class="one">{{$t('index.main1_2')}}</p>
          <p class="two">{{$t('index.main1_3')}}</p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <i class="iconfont icon-yuandaima"></i>
          <h3>{{$t('index.main2_1')}}</h3>
          <p class="one">{{$t('index.main2_2')}}</p>
          <p class="two">{{$t('index.main2_3')}}</p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <i class="iconfont icon-Smile"></i>
          <h3>{{$t('index.main2_3')}}</h3>
          <p class="one">{{$t('index.main3_2')}}</p>
          <p class="two">{{$t('index.main3_3')}}
            <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=597701573&site=qq&menu=yes">
              <img class="concat" src="https://qny.gqqf.xyz/concat.gif" alt="Loading" />
            </a>
          </p>
        </el-col>
      </el-row>
    </div>
    <!-- 插件 -->
    <div class="ext">
      <!-- 联系我们 -->
      <!-- 用户反馈 -->
    </div>
    <!-- 页脚 -->
    <div class="foot">
      <Foot/>
    </div>
  </div>
</template>

<script>
import Foot from "@/components/global/foot";
import Lang from "@/components/global/lang";

export default {
  name: "Index",
  components: {Lang, Foot},
  data() {
    return {
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.index {

  .head {
    margin-top: 36px;
    width: 100%;
    height: 520px;

    img {
      width: 100%;
      height: 100%;
    }

    &_banner {
      box-sizing: border-box;
      padding: 46px 0;
      width: 100%;
      height: 100%;
      background: #3398cc;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      align-content: space-around;
      justify-content: space-around;

      &_item1, &_item2, &_item3, &_item4 {
        font-size: 32px; // 32px 12px
        font-weight: 600;
        font-family: "黑体";
        text-align: center;
        .socialize {
          font-size: 40px;
          font-weight: 800;
        }

        .text_danger {
          // color: #904b48;
          color: #f7391c;
        }
      }

      &_item2 {
        font-size: 48px; // 48px 18px
      }

      &_item1, &_item3 {
        font-family: "宋体";
      }

      &_item4 {
        color: #303133;
      }
    }

    &_banner2 {
      box-sizing: border-box;
      padding: 46px 0;
      width: 100%;
      height: 100%;
      background: #3398cc;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      align-content: space-around;
      justify-content: space-around;

      &_item1, &_item2, &_item3, &_item4 {
        font-size: 12px; // 32px 12px
        font-weight: 600;
        font-family: "黑体";
        text-align: center;
        .socialize {
          font-size: 20px;
          font-weight: 800;
        }

        .text_danger {
          // color: #904b48;
          color: #f7391c;
        }
      }

      &_item2 {
        font-size: 18px; // 48px 18px
      }

      &_item1, &_item3 {
        font-family: "宋体";
      }

      &_item4 {
        color: #303133;
      }
    }
  }

  .main {
    box-sizing: border-box;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding-top: 40px;

    .item {
      padding: 24px;
      background: #fff;
      text-align: center;
      color: #000;

      .iconfont {
        font-weight: 400;
        font-size: 60px;
        color: #ca130d;
      }

      h3 {
        margin-top: 20px;
        text-align: center;

        a {
          color: #000;
        }
      }

      .one {
        margin-top: 24px;
        //text-align: center;
        text-indent: 2rem;
      }

      .two {
        margin-top: 4px;
        margin-bottom: 24px;
        //text-align: center;
        text-indent: 2rem;
      }

      p {
        line-height: 1.5rem;
        text-align: left;
        text-indent: 2rem;

        .concat {
          vertical-align: middle;
        }
      }
    }

    .el-col {
      border-radius: 4px;
    }

    .bg-purple-dark {
      background: #99a9bf;
    }

    .bg-purple {
      background: #d3dce6;
    }

    .bg-purple-light {
      background: #e5e9f2;
    }

    .grid-content {
      border-radius: 4px;
      min-height: 36px;
    }
  }

  .foot {
    padding-top: 40px;
  }
}
</style>
