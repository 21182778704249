// 英语

// 测试
export const demo = {
    demo: 'test'
}

// 常量
export const common = {
    name: 'Program software development'
}

// 组件
// lang
export const lang = {
    langObjs: [
        {sx:'en',name:'English',src:require('@/assets/imgs/gj/en.png')},
        {sx:'tw',name:'Traditional Chinese',src:require('@/assets/imgs/gj/tw.png')},
        {sx:'cn',name:'Chinese',src:require('@/assets/imgs/gj/cn.png')},
        {sx:'jp',name:'Japanese',src:require('@/assets/imgs/gj/jp.png')},
        {sx:'kr',name:'Korean',src:require('@/assets/imgs/gj/kr.png')},
        {sx:'tr',name:'Turkish',src:require('@/assets/imgs/gj/tr.png')},
    ]
}
// foot
export const foot = {
    foot1: "Copyright",
    foot2: "Anhui ICP Preparation No. 2020021352-2",
}

// 页面
// index
export const index = {
    head1_1: "Professional imitation website secondary modification development website template customization",
    head1_2: "QQ：597701573",
    head2_1: "Various backend open-source systems",
    head3_1: "Supporting secured transactions",
    head3_2: "Rest assured and dissatisfied, full refund",
    head3_3: "WeChat: V946357",
    head4_1: "Fast speed, low price, good after-sales service",
    head4_2: "If it's fast, it'll be completed in 2 hours",
    main1_1: "business scope",
    main1_1_1: "QQ：597701573",
    main1_2: "Various website construction, template copying, website production, website development, various website technology solutions, and functional development!",
    main1_3: "Standardized cooperation processes can effectively ensure our cooperation, which can proceed in an orderly manner on time, with quality, and quantity!",
    main2_1: "Program secondary development",
    main2_2: "Solution to various problems with program template installation Template secondary development Template debugging Template customization!",
    main2_3: "Serve only one customer at a time to ensure development quality and strictly follow pre agreed design!",
    main3_1: "6 years of experience",
    main3_2: "6 years of experience, consistently, truly serving every customer well!",
    main3_3: "Support various transaction methods: deposit transfer, guarantee transaction, etc! Safer and more reassuring!",
}
