// 日语

export const demo = {
    demo: 'テスト'
}

// 常量
export const common = {
    name: 'プログラムソフトウェア開発'
}

// 组件
// lang
export const lang = {
    langObjs: [
        {sx:'en',name:'英語',src:require('@/assets/imgs/gj/en.png')},
        {sx:'tw',name:'繁体字',src:require('@/assets/imgs/gj/tw.png')},
        {sx:'cn',name:'中国語',src:require('@/assets/imgs/gj/cn.png')},
        {sx:'jp',name:'日本語',src:require('@/assets/imgs/gj/jp.png')},
        {sx:'kr',name:'韓国語',src:require('@/assets/imgs/gj/kr.png')},
        {sx:'tr',name:'トルコ語',src:require('@/assets/imgs/gj/tr.png')},
    ]
}
// foot
export const foot = {
    foot1: "著作権所有",
    foot2: "皖ICP備2020021352号-2",
}

// 页面
// index
export const index = {
    head1_1: "専門サイト模倣サイトの二次修正開発サイトテンプレートのカスタマイズ",
    head1_2: "QQ：597701573",
    head2_1: "各種システムバックグラウンドオープンソースシステム",
    head3_1: "保証取引のサポート",
    head3_2: "安心安心安心不満全額返金",
    head3_3: "ウィーチャット：V 946357",
    head4_1: "スピードが速くて価格が低くてアフターサービスが良い",
    head4_2: "早ければ2時間で完成",
    main1_1: "業務範囲",
    main1_1_1: "QQ：597701573",
    main1_2: "各種サイト構築、テンプレート複製、サイト制作、サイト開発各種サイト技術解決、機能開発！",
    main1_3: "標準化された協力プロセスは、私たちの協力を効果的に保証し、時間、品質、量の順序で行うことができます！",
    main2_1: "プログラム二次開発",
    main2_2: "プログラムテンプレートインストール各種問題解決テンプレート二次開発テンプレートデバッグテンプレートカスタマイズ！",
    main2_3: "同じタイミングで1人のお客様にサービスを提供し、開発の品質を保証し、事前に約束された通りに厳格に設計します！",
    main3_1: "6年間の経験",
    main3_2: "6年間の経験、終始一貫して、本当にすべてのお客様にサービスを提供します！",
    main3_3: "各種取引方式をサポート：手付金振替、保証取引など！より安全で、より安心！",
}
