// 中文

export const demo = {
    demo: '测试'
}

// 常量
export const common = {
    name: '程序软件开发'
}

// 组件
// lang
export const lang = {
    langObjs: [
        {sx:'en',name:'英语',src:require('@/assets/imgs/gj/en.png')},
        {sx:'tw',name:'繁体',src:require('@/assets/imgs/gj/tw.png')},
        {sx:'cn',name:'中文',src:require('@/assets/imgs/gj/cn.png')},
        {sx:'jp',name:'日语',src:require('@/assets/imgs/gj/jp.png')},
        {sx:'kr',name:'韩语',src:require('@/assets/imgs/gj/kr.png')},
        {sx:'tr',name:'土耳其语',src:require('@/assets/imgs/gj/tr.png')},
    ]
}
// foot
export const foot = {
    foot1: "版权所有",
    foot2: "皖ICP备2020021352号-2",
}


// 页面
// index
export const index = {
    head1_1: "专业仿站 网站二次修改开发 网站模板定制",
    head1_2: "QQ：597701573",
    head2_1: "各种系统后台 开源系统",
    head3_1: "支持担保交易",
    head3_2: "放心安心 不满意全额退款",
    head3_3: "微信：V946357",
    head4_1: "速度快 价格低 售后好",
    head4_2: "快的话2小时完成",
    main1_1: "业务范围",
    main1_1_1: "QQ：597701573",
    main1_2: "各种网站建设，模板复制，网站制作，网站开发各种网站技术解决，功能开发！",
    main1_3: "标准化合作流程，能够有效保证我们的合作，能够按时、按质、按量的有序进行！",
    main2_1: "程序二次开发",
    main2_2: "程序模板安装 各种问题解决 模板二次开发 模板调试 模板定制！",
    main2_3: "同一时刻只为一位客户服务，以保证开发质量，严格按照事先约定设计！",
    main3_1: "6年经验",
    main3_2: "6年经验，始终如一，真正做到服务好每一位客户！",
    main3_3: "支持各种交易方式：定金转账、担保交易等！更安全，更放心！",
}
